<template>
  <div class="w-100">
    <div v-if="!account">
      <v-col class="p-5"> Veuillez d'abord créer votre compte </v-col>
    </div>

    <div v-else>
      <div>
        <v-alert dense color="orange lighten-5" class="my-5" icon="info">
          <span>
            Dans le cadre de la procédure KYC, chaque nouvel utilisateur doit
            fournir les pièces justificatives suivantes,

            <p
              class="font-weight-bold pt-2"
              style="margin-bottom: 0px !important"
            >
              Une pièce d'identité parmi les 4 suivantes:
            </p>
            <ul style="list-style-type: circle; list-style-position: inside">
              <li>Passeport</li>
              <li>Carte d’identité</li>
              <li>Titre de séjour</li>
              <li>Permis de conduire</li>
            </ul>
            <p class="font-weight-bold" style="margin-bottom: 0px !important">
              Les 3 pièces suivantes pour la structure:
            </p>
            <ul style="list-style-type: circle; list-style-position: inside">
              <li>Procès-verbal association</li>
              <li>Statuts datés et signés</li>
              <li>Parution au JOAFE</li>
            </ul>
          </span>
        </v-alert>
      </div>

      <div style="font-size: 0.8rem; color: black">
        <v-row>
          <v-col cols="3">Nom du compte KYC</v-col>
          <v-col class="d-flex align-center coordonnes_result" cols="3">{{
            account
          }}</v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <ValidateForm @validated="submit" :showFieldObl="false">
            <div class="form-user-details__container mt-6">
              <!-- <InputText
              v-model="accountKyc"
              rules="min:0|max:50"
              label="Nom du compte KYC"
            ></InputText> -->
              <selectInput
                v-model="documentType"
                :items="documentUpload"
                rules="required"
                item_text="name"
                item_value="value"
                label="Type de document à téléverser"
              ></selectInput>
            </div>

            <v-row justify="center">
              <v-col cols="12" class="inner-card-preview" style="">
                <div class="text-center inner-upload-preview" style="">
                  <div>
                    <v-icon right dark color="indigo darken-4">mdi-file</v-icon>
                  </div>

                  <div v-if="fileURL">
                    {{ formatedFileName }}
                  </div>
                  <div v-else>
                    fichiers autorisés: jpg, jpeg, png ou pdf | 5 MO max
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-center">
                <input
                  style="display: none"
                  accept=".jpg,.jpeg,.png,.pdf"
                  @change="processFile"
                  type="file"
                  ref="fileInput"
                />
                <v-btn class="btn btn-primary" @click="triggerWindowUpload">
                  Téléverser un document
                  <!-- <v-icon right dark>mdi-cloud-upload</v-icon> -->
                </v-btn>
              </v-col>
            </v-row>

            <div class="form-user-details__action">
              <v-btn
                type="submit"
                class="btn btn-primary mt-5"
                :disabled="!alreadyRegister || !fileURL"
                >Envoyer le document</v-btn
              >
            </div>
          </ValidateForm>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
// import InputText from "@/components/utils/input.vue";
import selectInput from "@/components/utils/select.vue";
import ValidateForm from "@/components/utils/form.vue";

export default {
  components: { /* InputText */ selectInput, ValidateForm },
  data: () => ({
    fileURL: null,
    accountKyc: "",
    documentType: null,
    allDocumentType: [
      { name: "pièce d'identité - Passeport (UE)", value: 3 },
      { name: "pièce d'identité - Passeport (hors UE)", value: 4 },
      { name: "pièce d'identité - Carte d'identité", value: 0 },
      { name: "pièce d'identité - Titre de séjour", value: 5 },
      { name: "pièce d'identité - Permis de conduire", value: 11 },
      { name: "Procès-verbal association (moins d’un an)", value: 17 },
      { name: "Statuts datés et signés", value: 12 },
      { name: "Parution au JOAFE", value: 18 },
    ],
    getAllPaymentAccount: [],
    file: "",
    structure: {},
  }),

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),

    formatedFileName() {
      return this.file.name;
    },
    alreadyRegister() {
      if (this.accountKyc === undefined) return false;
      return true;
    },
    account() {
      return this.accountKyc;
    },
    documentUpload() {
      let documents = this.allDocumentType;
      const slug = this.structure.STR_Structure.LIST_StructureType
        .SlugTypeStructure;
      if (slug === "club_etablissement_affilie") {
        documents = [
          {
            name: "Pièce d'identité - Carte d'identité (Obligatoire)",
            value: 0,
          },
          {
            name: "Pièce d'identité - Bénéficiaire effectif (Facultatif)",
            value: 19,
          },
          { name: "KBIS - attestant création (moins de trois mois)", value: 7 },
          { name: "Statuts datés et signés", value: 12 },
        ];
      }
      return documents;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUserPaymentAccount",
      "getUserPaymentAccountStructure",
    ]),

    async setData() {
      this.getAllPaymentAccount = await this.getUserPaymentAccountStructure(
        this.currentStructureId
      );
      // this.haveAccount = this.getAllPaymentAccount.find(
      //   (account) => account.ID_Structure == this.currentStructureId
      // );
      // console.log("this.haveAccount !!!!", this.haveAccount);

      this.accountKyc = this.getAllPaymentAccount[0].NomCompte;
      this.structure = this.currentStructure;
    },

    // Simulate the click on the hidenn input file
    triggerWindowUpload() {
      this.$refs.fileInput.click();
    },

    // convert the input value into file object and check his validity
    async processFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];

      // console.log("files====>", this.file);

      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        const splitBase64 = reader.result.split(",");
        this.iconBase64 = splitBase64[1];
        // console.log("iconBase64 result:", this.iconBase64);
      };

      reader.onerror = function(error) {
        console.log("Error: ", error);
      };

      // Check size and file type
      if (!(await this.checkFile())) return false;

      await this.readFile();

      return true;
    },

    // Chek file size and extesion
    async checkFile() {
      if (!this.checkExtension()) {
        error_notification(
          "Les formats valides sont les suivants: jpg, jpeg, png et pdf."
        );
        return false;
      }

      if (!this.checkFileSize()) {
        error_notification("Le fichier est trop volumineux (5Mo max).");
        return false;
      }

      return true;
    },

    // convert file into a string for upload
    async readFile() {
      // Convert file into url transferable string
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileURL = event.target.result;
          return true;
        };
        reader.readAsDataURL(this.file);
      } else {
        error_notification("Désolé, une erreur est survenue.");
        console.log("FileReader API not supported");
      }
    },
    // Check file size 0-0
    checkFileSize() {
      const maxFileSize = 5_000_000; // == 5 MO
      if (this.file.size > maxFileSize) {
        return false;
      }
      return true;
    },

    // get file extension
    getFileExtension() {
      let fileExt = this.file.type.split("/");
      if (!fileExt[1]) return false;
      return fileExt[1];
    },

    // Check file extension
    checkExtension() {
      const allowedFileType = ["jpg", "jpeg", "png", "pdf"];

      let isValid = allowedFileType.find(
        (type) => type == this.getFileExtension()
      );
      if (isValid) return true;
      return false;
    },

    async submit() {
      let dataUpload = {
        name: this.formatedFileName,
        type: this.documentType,
        buffer: this.iconBase64,
      };
      // console.log(`dataUpload`, dataUpload);

      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/dataDocumentKyc`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(dataUpload),
          }
        );

        const dataDocumentKyc = await response;
        // console.log("dataDocumentKyc ====>", dataDocumentKyc);
        if (dataDocumentKyc.ok === true) {
          await this.callUploadDocument();
        }
      } catch (error) {
        console.log("erro r:", error);
      }
    },

    async callUploadDocument() {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/uploadDocumentKyc/${this.accountKyc}`,
          {
            method: "get",
            headers: headers,
          }
        );
        const json = await response.json();
        // console.log("response call upload====>", json);
        if (json.uploadDocument) {
          success_notification("Document envoyé");
        } else if (
          json.error.message ==
          "A document of the same type is already waiting for review"
        ) {
          error_notification(`Ce document est déjà télécharger`);
        } else if (json.error.message == "DUPLICATED_DOCUMENT_FOUND") {
          error_notification(`Ce Type de document est déjà télécharger`);
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log("erro r:", error);
        // error_notification(`${error}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}

.coordonnes_result {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
}
</style>
