<template>
  <div v-if="!mounted">En cours de chargement</div>
  <div v-else class="pb-6" style="padding: 30px;">
    <!-- Activite du club -->
    <h1 class="font-weight-medium" style="font-size: 20px;">Étape 2 - Saisie des activités à joindre</h1>
    <ValidateForm @validated="submit" :showFieldObl="false" :loading="loading">
      <ActivitesSructure
        :key="keyMainActivity"
        :disabled="disabled"
        :currentActivites="currentStructureActivites"
        @onChange="activitesChange"
        :showFieldObl="mandatory"
        :isThereMainActivity="isThereMainActivity"
        :isTryingToSubmit="isTryingToSubmit"
      />

      <RadioGroup
        name="CLUB_EST_AcceuillantHandicape"
        v-model="affiliations.CLUB_EST_AcceuillantHandicape"
        :checked="affiliations.CLUB_EST_AcceuillantHandicape"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Accueil de personnes en situation de handicap"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <SelectInput
        v-if="affiliations.CLUB_EST_AcceuillantHandicape"
        label="Type de handicap"
        rules="required"
        :items="listDisabilities"
        v-model="structureDisabilities"
        item_text="name"
        item_value="id"
        multiple
      ></SelectInput>

      <RadioGroup
        name="CLUB_EST_HandiGuide"
        v-model="affiliations.CLUB_EST_HandiGuide"
        :checked="affiliations.CLUB_EST_HandiGuide"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Êtes-vous inscrits au Handi Guide du Ministère des Sports ?"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_AcceuillantMineur"
        v-model="affiliations.CLUB_EST_AcceuillantMineur"
        :checked="affiliations.CLUB_EST_AcceuillantMineur"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Mineurs acceptés"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <InputText
        v-model="affiliations.CLUB_AcceuillantMineurAge"
        label="Âge minimum"
        type="number"
        :disabled="disabled"
        inputStyle="width:15%"
        rules="required"
        :minValue="1"
      ></InputText>

      <RadioGroup
        name="CLUB_EST_AcceptePassSportCulture"
        v-model="affiliations.CLUB_EST_AcceptePassSportCulture"
        :checked="affiliations.CLUB_EST_AcceptePassSportCulture"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Propose les passeports escalade"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_PretMateriel"
        v-model="affiliations.CLUB_EST_PretMateriel"
        :checked="affiliations.CLUB_EST_PretMateriel"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Prêt de matériel (EPI)"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_ConventionAllocationFamilliale"
        v-model="affiliations.CLUB_EST_ConventionAllocationFamilliale"
        :checked="affiliations.CLUB_EST_ConventionAllocationFamilliale"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Convention avec les Caisses d'Allocation Familialles"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_AccepteChequesVacances"
        v-model="affiliations.CLUB_EST_AccepteChequesVacances"
        :checked="affiliations.CLUB_EST_AccepteChequesVacances"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Accepte les chèques vacances"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_AccepteCouponSport"
        v-model="affiliations.CLUB_EST_AccepteCouponSport"
        :checked="affiliations.CLUB_EST_AccepteCouponSport"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Accepte les coupons sport"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>

      <RadioGroup
        name="CLUB_EST_PreparePasseport"
        v-model="affiliations.CLUB_EST_PreparePasseport"
        :checked="affiliations.CLUB_EST_PreparePasseport"
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Accepte les pass'sport culture"
        :disabled="disabled"
        rules="required"
      ></RadioGroup>
      <br />
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 15px;">
        <v-btn
          :disabled="!hasCodeFederal || disabled"
          type="submit"
          :loading="loading"
          class="btn btn-primary"
          >Enregistrer les informations de cette étape</v-btn
        >
        <v-btn
          :disabled="!hasCodeFederal || disabled || !stepIsValid"
          type="button"
          :loading="loading"
          class="btn btn-primary"
          @click="() => { this.$router.push({ name: 'affiliations__documents_a_joindre' }) }"
          >Étape suivante</v-btn
        >
      </div>
    </ValidateForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RadioGroup from "@/components/utils/radioGroup.vue";
import InputText from "@/components/utils/input.vue";
import SelectInput from "@/components/utils/select.vue";

import ValidateForm from "@/components/utils/form.vue";
import ActivitesSructure from "@/components/Activites/Activites__form.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

import { bus } from "@/main";

export default {
  components: { ValidateForm, ActivitesSructure, RadioGroup, InputText, SelectInput },

  data: () => ({
    selectedSeason: null,
    mounted: false,
    currentStructureActivites: null,
    loading: false,
    affiliations: {},
    mandatory: false,
    listDisabilities: [],
    structureDisabilities: [],
    stepIsValid: false,
    isThereMainActivity: true,
    keyMainActivity: 0,
    isTryingToSubmit: false,
  }),

  async created() {
    await this.getStructureActivite(this.currentStructureId);
    this.listDisabilities = await this.getListDisabilities();

    
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["saisons"]),

    disabled() {
      if (!this.affiliations) return false;
      return this.affiliations.ID_EtapeAffiliation == 3;
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureActivite",
      "updateStructureActivite",
      "getStructureAffiliation",
      "updateAffiliationStrucure",
      "getStructureDisabilities",
    ]),

    ...mapActions("basics", ["getListDisabilities"]),

    async setData() {
      this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
      this.currentStructureActivites = [
        ...this.currentStructure.ACTI_ActivitePratiquee,
      ];
      this.affiliations = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      this.structureDisabilities = await this.getStructureDisabilities(this.currentStructureId);
      this.structureDisabilities = this.structureDisabilities.map(
        (disability) => disability.id_disability
      );

      this.validateStep();

      console.log(!this.hasCodeFederal, this.disabled, !this.stepIsValid);

      return;
    },

    activitesChange(activites) {
      console.log("activites activitesChange", activites);
      this.currentStructureActivites = activites;
    },

    async submit() {
      this.loading = true;
      try {
        this.isTryingToSubmit = true;
        let activities = await this.getStructureActivite(this.currentStructureId);
        console.log(
          "this.currentStructureActivites",
          this.currentStructureActivites
        );
        let activites = this.currentStructureActivites.map((act) => ({
          ID_Structure: this.currentStructureId,
          ID_Activite: act.ID_Activite ? act.ID_Activite : act.id,
          EST_ActivitePrincipale: act.EST_ActivitePrincipale,
          Ordre: act.LIST_ActiviteType
            ? act.LIST_ActiviteType.Ordre
            : act.Ordre,
        }));

        console.log("activitessssssssss", activites);

        const newActivity = await this.updateStructureActivite({
          Activites: activites,
          ID_Structure: this.currentStructureId,
        });

        let affiliation = this.affiliations;
        delete affiliation.__typename;
        delete affiliation.DOC_CompteRenduAg;
        delete affiliation.DOC_RapportTresorerie;
        delete affiliation.DOC_Status;
        delete affiliation.DOC_ParutionJO;
        delete affiliation.DOC_Rib;
        delete affiliation.DOC_ContratClub;
        delete affiliation.DOC_RecepissePrefectoralDeclaration;
        delete affiliation.CollecteurFormationProfessionnelle;
        delete affiliation.LIST_EtapeAffiliation;
        delete affiliation.DOC_ReglementDisciplinaire;
        delete affiliation.DOC_ReglementInterieur;
        delete affiliation.DOC_DiplomeEncadrant;
        delete affiliation.DOC_CarteProfessionnelle;
        delete affiliation.DOC_AttestationAssurance;
        delete affiliation.DOC_ReglementFinancier;
        delete affiliation.DOC_Rgpd;
        delete affiliation.DOC_ReglementMedical;
        delete affiliation.DOC_ReglementAntiDopage;
        delete affiliation.DOC_ReglementDisciplinaire;
        delete affiliation.DOC_BilanFinancier;
        delete affiliation.DOC_CompteResultat;
        delete affiliation.DOC_Rib;
        delete affiliation.DOC_Mandat;

        await this.updateAffiliationStrucure({
          affiliation: affiliation,
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSeason.id,
          disabilities: affiliation.CLUB_EST_AcceuillantHandicape ? this.structureDisabilities.map((handicapId) => { return { id_structure: this.currentStructureId, id_disability: handicapId }; }) : [],
        });
        // console.log(this.currentStructure.ACTI_ActivitePratiquee);
        this.setData();
        success_notification("La modification de l’étape est un succès, poursuivez votre réaffiliation en allant à l’étape suivante");

        this.loading = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
        console.log(e);
      }

      this.validateStep();
    },

    async validateStep() {
      let activities = await this.getStructureActivite(this.currentStructureId);

      this.isThereMainActivity = activities.some((activity) => activity.EST_ActivitePrincipale === true);

      if (activities.length === 0 || !this.isThereMainActivity) {
        this.stepIsValid = false;
        bus.$emit('onRefreshNavigation', false);

        return false;
      }

      if (
        this.affiliations.CLUB_EST_AcceuillantHandicape !== null &&
        ((this.affiliations.CLUB_EST_AcceuillantHandicape === true && this.structureDisabilities.length > 0) || this.affiliations.CLUB_EST_AcceuillantHandicape === false) &&
        this.affiliations.CLUB_EST_AcceuillantMineur !== null &&
        this.affiliations.CLUB_AcceuillantMineurAge !== null &&
        this.affiliations.CLUB_EST_AcceptePassSportCulture !== null &&
        this.affiliations.CLUB_EST_PretMateriel !== null &&
        this.affiliations.CLUB_EST_ConventionAllocationFamilliale !== null &&
        this.affiliations.CLUB_EST_AccepteChequesVacances !== null &&
        this.affiliations.CLUB_EST_AccepteCouponSport !== null &&
        this.affiliations.CLUB_EST_PreparePasseport !== null
      ) {
        this.stepIsValid = true;
        bus.$emit('onRefreshNavigation', true);

        return true;
      }

      this.stepIsValid = false;
      bus.$emit('onRefreshNavigation', false);

      return false;
    },
  },
};
</script>
